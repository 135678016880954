<template>
  <div>
      <grid-head-layout
        ref="gridHeadLayout"
        :search-columns="searchColumns"
        @grid-head-search="gridHeadSearch"
        @grid-head-empty="gridHeadEmpty"
      ></grid-head-layout>
    <grid-layout
      ref="gridLayOut"
      :table-options="tableOptions"
      :table-data="tableData"
      :table-loading="tableLoading"
      :data-total="page.total"
      :page="page"
      @page-current-change="handleCurrentChange"
      @page-size-change="handleSizeChange"
      @page-refresh-change="onLoad"
    >
      <template #customBtn="{row}">
        <el-button
          size="small"
          type="text"
          @click="addPostManage(row)"
        >确 认
        </el-button>
      </template>
    </grid-layout>
  </div>
</template>

<script>
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";

import {mapGetters} from "vuex";
import {getList} from "@/api/training/edupaper";
import {getEduTree} from "@/api/training/edutree";


export default {
  name: "exampaperDialog",
  components: {
    HeadLayout,
    GridLayout,
  },
  data() {
    return {
      query: {},
      page: {
        pageSize: 10,
        currentPage: 1,
        total: 0
      },
      tableData: [],
      tableLoading: false,
    }
  },
  computed: {
    ...mapGetters(["permission", 'colorName']),
    permissionList() {
      return {
        addBtn: this.vaildData(this.permission.eduexam_add, true),
        viewBtn: this.vaildData(this.permission.eduexam_view, true),
        delBtn: this.vaildData(this.permission.eduexam_delete, true),
        editBtn: this.vaildData(this.permission.eduexam_edit, true)
      };
    },
    searchColumns() {
      return [
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "试卷编号",
          prop: "code",
          span:'4'
        },
        {
          placeholder: this.$t('cip.cmn.rule.inputWarning') + "试卷名称",
          prop: "paperName",
          span:'4'
        },

      ]
    },
    tableOptions() {
      return {
        menu: true,
        selection: false,
        column: [
          {
            label: "试卷编号",
            prop: "code",
            sortable: true,
            width: 120,
          },
          {
            label: "试卷名称",
            prop: "paperName",
            sortable: true,
            overHidden: true,
          },
          // {
          //   label: "考试级别",
          //   prop: "lexaminationLevel",
          //   sortable: true,
          //   type: 'select',
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_EXAMINATION_LEVEL',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   width: 140,
          // },
          // {
          //   label: "出题依据",
          //   prop: "accordingTo",
          //   sortable: true,
          //   type: 'select',
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=EDUCATION_ACCORDING_TO',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   width: 140,
          //   overHidden: true,
          // },
          {
            label: "专业分类",
            prop: "subjectType",
            sortable: true,
            dicData: [],
            type: "tree",
            props: {
              label: "title",
              value: "value",
            },
            overHidden: true,
          },
          {
            label: "试卷说明",
            prop: "paperExplain",
            sortable: true,
            overHidden: true,
          },
          {
            label: "答题时长(分钟)",
            prop: "answerTime",
            sortable: true,
            width: 140,
            align: 'right',
          },
          {
            label: "试卷状态",
            prop: "paperStatus",
            sortable: true,
            type: 'select',
            dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=paper_status',
            props: {
              label: 'dictValue',
              value: 'dictKey',
            },
            width: 110,
            align: 'center',
          },
          // {
          //   label: "是否公布答案",
          //   prop: "openAnswer",
          //   sortable: true,
          //   type: 'select',
          //   dicUrl: '/api/sinoma-system/dict-biz/dictionary?code=YES_NO',
          //   props: {
          //     label: 'dictValue',
          //     value: 'dictKey',
          //   },
          //   width: 130,
          //   align: 'center',
          // },
        ]
      }
    },
  },
  methods: {
    initData() {
      getEduTree().then(res => {
        const column = this.findObject(this.$refs.gridLayOut.option.column, 'subjectType')
        column.dicData = res.data.data
      })
    },
    addPostManage(row) {
      this.$emit('getSelectionData', row);
    },
    gridHeadSearch(query) {
      this.page.currentPage = 1;
      this.onLoad(this.page, query)
    },
    gridHeadEmpty(query) {
      this.onLoad(this.page, query)
    },
    //页数改变
    handleCurrentChange(e) {
      this.page.currentPage = e.currentPage
      this.onLoad(this.page)
    },
    //size改变
    handleSizeChange(e) {
      this.page.pageSize = e.pageSize
      this.onLoad(this.page)
    },
    onLoad(page, params = {}) {
      this.tableLoading = true;
      this.query.paperStatus = '1';
      this.query.isPaper = '1';
      getList(page.currentPage, page.pageSize, Object.assign(params, this.query)).then(res => {
        const data = res.data.data;
        this.page.total = data.total;
        this.tableData = data.records;
        this.tableLoading = false;
        this.$refs.gridLayOut.selectionClear();
      });
    }
  },
  mounted() {
    this.initData()
  },
  created() {
    this.onLoad(this.page)
  }
}
</script>

<style scoped>

</style>
